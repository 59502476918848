:root
{
  --theme-heading-font: 'PPFuji', sans-serif;
  --theme-font: 'Poppins', sans-serif;

  --primary: #e28623;
  --primary-rgb: 226, 134, 35;
  --primary-light: #fcf5ed;
  --primary-light-rgb: 252, 245, 237;
  --primary-hover: #fc9526;
  --primary-hover-rgb: 252, 149, 38;
  --primary-dark: #c9771e;
  --primary-dark-rgb: 201, 119, 30;
  
  --secondary: #428db1;
  --secondary-rgb: 66, 141, 177;
  --secondary-light: #f4f8f9;
  --secondary-light-rgb: 244, 248, 249;
  --secondary-hover: #4ba1c9;
  --secondary-hover-rgb: 75, 161, 201;
  --secondary-dark: #387896;
  --secondary-dark-rgb: 56, 120, 150;
  
  --white: #fff;
  --white-rgb: 255, 255, 255;
  --black: #000;
  --black-rgb: 0, 0, 0;
  
  --light: #f4f8f9;
  --light-rgb: 244, 248, 249;
  --dark: #28547c;
  --dark-rgb: 40, 84, 124;
  --dark-hover: #306596;
  --dark-hover-rgb: 48, 101, 150;
  
  --font-color: #667073;
  --font-color-rgb: 102, 112, 115;
  --theme-heading-color: #000000;
  --theme-heading-color-rgb: 0, 0, 0;
  
  --border-color: #292663;
  --border-color-rgb: 41, 38, 99;
  --border-color-light: #d2d2e0;
  --border-color-light-rgb: 210, 210, 224;
}

/* Add import statement for custom font here */
@font-face {
  font-family: 'PPFuji';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../web/fonts/PPFuji-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'PPFuji';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../web/fonts/PPFuji-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../web/fonts/Poppins-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../web/fonts/Poppins-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../web/fonts/Poppins-Medium.woff2) format('woff2');
}
body
{
  font-family: var(--theme-font);
  color: var(--font-color);
  font-weight:400;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6
{
  font-family: var(--theme-heading-font);
  color: var(--theme-heading-color);
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-weight: 300;
}

h1:has(+ .subkop),
h2:has(+ .subkop),
h3:has(+ .subkop),
h4:has(+ .subkop),
h5:has(+ .subkop),
h6:has(+ .subkop),
.h1:has(+ .subkop),
.h2:has(+ .subkop),
.h3:has(+ .subkop),
.h4:has(+ .subkop),
.h5:has(+ .subkop),
.h6:has(+ .subkop)
{
  margin-bottom:0;
}

.subkop
{
  color: var(--secondary);
  font-weight: 400;
  font-size:18px;
  text-transform:uppercase;
}

.supkop
{
  color: var(--secondary);
  margin-bottom:0;
  font-weight: 400;
  font-size:18px;
  text-transform:uppercase;
}

.lead
{
  font-weight:600;
}
p 
{
  margin-bottom: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a 
{
  color: var(--secondary);
  text-decoration: underline;
}
/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}
/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0 0 20px;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}
ul.checkmarks,
ul.kruisjes,
ul.arrow,
ul.angles,
ul.dashes,
ul.plusjes {
  list-style: none;
  padding:0;
  position: inherit;
  width: auto;
  height: auto;
}
ul.checkmarks li,
ul.kruisjes li,
ul.arrow li,
ul.angles li,
ul.dashes li,
ul.plusjes li 
{
  display:table;
}
ul.checkmarks li:before {
    content:"";
    background:url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><path fill="%23428db1" d="M191.9,304.6c2.3-2.8,3.4-4.4,4.8-5.8c64.9-64.9,129.7-129.8,194.6-194.6c16.2-16.2,34.2-16.2,50.4,0c3.9,3.9,7.8,7.7,11.6,11.6c13.5,14,13.5,32.7-0.2,46.5c-20.2,20.4-40.5,40.5-60.7,60.8C331.2,284.2,270.1,345.3,209,406.5c-6.8,6.8-14.4,10.1-23.7,6.8c-3.3-1.2-6.5-3.4-9-5.9c-39.4-39.3-78.8-78.6-118.1-118C44.4,275.7,44.5,256.8,58,243c4.5-4.6,9.1-9.2,13.7-13.7c13.7-13.3,32.6-13.4,46.2,0.1c23.4,23.2,46.6,46.6,69.9,69.9C189.1,300.5,190,302.1,191.9,304.6z"/></g></svg>');
    width: 36px;
    height: 23px;
    background-size: 19px;
    background-position: 14px 3px;
    display: table-cell;
    background-repeat: no-repeat;
}
ul.dashes li:before {
    content:"";
    background: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><path fill="%23DD0000" d="M448.7,302.1H67.3c-6.6,0-12-5.4-12-12v-72.3c0-6.6,5.4-12,12-12h381.4c6.6,0,12,5.4,12,12v72.3C460.7,296.7,455.3,302.1,448.7,302.1z"/></svg>');
    width: 36px;
    height: 23px;
    background-size: 19px;
    background-position: 14px 5px;
    display: table-cell;
    background-repeat: no-repeat;
}
ul.arrow li:before,
ul.angles li:before,
ul.plusjes li:before
{
    font-family: Line Awesome Free;
    font-weight: 900;
    margin-right: 5px;
    font-size: 16px;
    padding-left: 7px;
}
ul.arrow li:before
{
    content: '\f061'
}
ul.angles li:before
{
    content: '\f105';
}
ul.plusjes li:before
{
    content: '\f067';
}
.button-primary
{
  display:inline-block;
  width: fit-content;
  margin-top: 15px;
}
.button-primary a
{
    width: auto;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border:1px solid var(--primary);
    background-color: var(--primary);
    color: #fff;
    white-space: normal;
    padding: 0.7rem 1.1rem;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    transition: all .3s ease;
}
.button-primary a:hover,
.button-primary a:focus,
.button-primary a:active,
.button-primary a.active,
.button-primary a.active:hover
{
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  text-decoration: none;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}
.button-secondary
{
  display:inline-block;
  width: fit-content;
  margin-top: 15px;
}
.button-secondary a
{
    width: auto;
    text-align: center;
    white-space: nowrap;
    transition: all .3s ease;
    vertical-align: middle;
    user-select: none;
    border:1px solid var(--secondary);
    background-color: var(--secondary);
    color: #fff;
    white-space: normal;
    padding: 0.7rem 1.1rem;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
}
.button-secondary a:hover,
.button-secondary a:focus,
.button-secondary a:active,
.button-secondary a.active,
.button-secondary a.active:hover
{
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
  text-decoration: none;
}
.button-secondary-outline
{
    display:inline-block;
    width: fit-content;
    margin-top: 15px;
}
.button-secondary-outline a
{
    width: auto;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border:1px solid var(--secondary);
    background-color: transparent;
    color: var(--secondary);
    white-space: normal;
    padding: 0.7rem 1.1rem;
    border-radius: 4px;
    transition: all .3s ease;
    text-decoration: none;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right:10px;
}
.button-secondary-outline a:hover,
.button-secondary-outline a:focus,
.button-secondary-outline a:active,
.button-secondary-outline a.active,
.button-secondary-outline a.active:hover
{
  background-color: var(--white);
  border-color: var(--secondary-hover);
  text-decoration: none;
  color: var(--secondary);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}
.button-innerlink
{
  display: inline-block;
  width: fit-content;
  margin-top: 10px;
}
.button-innerlink a
{
  width: auto;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-color: var(--light);
  color: var(--secondary);
  white-space: normal;
  padding: 0.4rem .8rem;
  border-radius: 4px;
  transition: all .3s ease;
  text-decoration: none;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.button-innerlink a:hover,
.button-innerlink a:focus,
.button-innerlink a:active,
.button-innerlink a.active,
.button-innerlink a.active:hover
{
  background-color: var(--secondary);
  text-decoration: none;
  color: var(--white);
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}
/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}

@media (min-width:1200px)
{
  .vw-lg-50
  {
    width:50vw!important;
    padding-left: 8px;
  }
}

main > :not(.widgetobj)
{
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}

main > :not(.widgetobj):first-child
{
  margin-top:30px;
}
main > :not(.widgetobj):last-child
{
  margin-bottom:30px;
}