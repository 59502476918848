.splide__track {
  z-index: 1;
}
.splide__pagination {
  bottom: -20px;
}
.splide__pagination__page {
  border-radius: 0;
}
.splide__pagination__page.is-active {
  background-color: var(--bs-primary);
}
.splide__arrows {
  bottom: -60px;
  position: relative;
}
.splide__arrow {
  background-color: transparent;
  opacity: 0.7;
  transition: all 0.1s ease-in;
  height: 1.5em;
  width: 1.5em;
  border-radius: 0;
  z-index: 3;
}
.splide__arrow:hover, .splide__arrow:focus {
  opacity: 1;
}
.splide__arrow svg {
  display: none;
}
.splide__arrow--next {
  bottom: 0;
  top: inherit;
  left: 35px;
}
.splide__arrow--next:before {
  content: "\f152";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 32px;
  color: var(--bs-secondary);
}
.splide__arrow--prev {
  bottom: 0;
  top: inherit;
  left: 0;
}
.splide__arrow--prev:before {
  content: "\f191";
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 32px;
  color: var(--bs-secondary);
}