@media (min-width: 1200px) {
  .ogd__productslider__intro {
    max-width: 550px;
    margin: 0 auto;
  }
}
.ogd__productslider .button-primary {
  display: block;
}
.ogd__productslider .splide__arrow--next:before,
.ogd__productslider .splide__arrow--prev:before {
  color: var(--secondary);
}