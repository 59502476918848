html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

html,
body {
  background-color: var(--white);
}

.rounded-sm {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.container,
.container-xl {
  transition: all 0.3s ease;
}

.bg-light {
  background-color: var(--light) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}
.bg-dark > * {
  color: var(--white);
}

.bg-white {
  background-color: var(--white) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}
.bg-primary > * {
  color: var(--white);
}

.bg-primary-light {
  background-color: var(--primary-light) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}
.bg-secondary > * {
  color: var(--white);
}

.bg-secondary-light {
  background-color: var(--secondary-light) !important;
}

.la-star-half-alt,
.la-star {
  color: var(--orange);
}

.form-control,
.form-select {
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.website--showconsentbar:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  pointer-events: none;
}

.consentbar__notice {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  min-width: 350px;
  z-index: 1030;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
}
.consentbar__notice .btn-link {
  color: var(--primary);
  vertical-align: baseline;
  border: 0;
  font-size: inherit;
}
.consentbar__notice .accept-consent {
  min-width: 200px;
  text-align: center;
}
@media (max-width: 575px) {
  .consentbar__notice .accept-consent {
    width: 100%;
  }
}
.consentbar__notice a {
  text-decoration: underline;
  color: var(--primary);
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1600px;
  }
}
@media (max-width: 565px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (min-width: 565.99px) and (max-width: 1199px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
@media (min-width: 1200px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
}
.htmlanker {
  scroll-margin-top: 150px;
}