.ogd__faq {
  position: relative;
}
.ogd__faq .card {
  margin-bottom: 10px;
  background-color: var(--light);
  border: 0 solid var(--border-color) !important;
}
.ogd__faq .card .card-header {
  background-color: var(--light);
  padding: 0 5px;
  margin-bottom: 0;
  border-bottom: 0;
  border-radius: 0.375rem;
}
.ogd__faq .card .card-header .btn-link {
  color: var(--dark);
  display: flex;
  width: 100%;
  text-align: left;
  padding: 0.5rem;
  text-decoration: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
}
.ogd__faq .card .card-header .btn:after {
  font-family: "Line Awesome Free";
  font-weight: 900;
  content: "\f146";
  float: right;
  color: var(--secondary);
  font-size: 20px;
}
.ogd__faq .card .card-header .btn.collapsed:after {
  content: "\f0fe";
}
.ogd__faq .card .card-body {
  color: var(--font-color);
}