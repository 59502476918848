.productsgrid__product a {
  display: flex;
  text-decoration: none;
}
.productsgrid__product a .productitem__image {
  position: relative;
}
.productsgrid__product a .productitem__image--normal {
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease;
}
.productsgrid__product a .productitem__image--override {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.productsgrid__product a:hover .productitem__image--override, .productsgrid__product a:focus .productitem__image--override {
  opacity: 1;
}
.productsgrid__product a:hover .productitem__image--normal, .productsgrid__product a:focus .productitem__image--normal {
  opacity: 0;
}