@media (max-width: 565px) {
  main > :not(#masthead) .btn {
    width: 100%;
  }
}

.btn {
  font-family: var(--theme-font);
  white-space: normal;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.btn-lg {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  font-size: 1rem;
}
.btn:active, .btn.active, .btn:focus .btn:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.btn-primary:active, .btn-primary.active, .btn-primary:focus, .btn-primary:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}
.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: #fff;
}
.btn-secondary:active, .btn-secondary.active, .btn-secondary:focus, .btn-secondary:hover {
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
  color: #fff;
}
.btn-secondary-outline {
  background-color: transparent;
  border-color: var(--secondary);
  color: var(--secondary);
}
.btn-secondary-outline:active, .btn-secondary-outline.active, .btn-secondary-outline:focus, .btn-secondary-outline:hover {
  background-color: var(--white);
  border-color: var(--secondary-hover);
  color: var(--secondary);
}
.btn-light {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}
.btn-light:active, .btn-light.active, .btn-light:focus, .btn-light:hover {
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
  color: #fff;
}
.btn i {
  font-size: 21px;
  position: relative;
  top: 2px;
}