::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
} /* Chrome <=56, Safari < 10 */
:-moz-placeholder {
  opacity: 1;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
} /* FF 4-18 */
::-moz-placeholder {
  opacity: 1;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
} /* FF 19-51 */
:-ms-input-placeholder {
  opacity: 1;
  -ms-transition: opacity 0.5s;
  transition: opacity 0.5s;
} /* IE 10+ */
::placeholder {
  opacity: 1;
  transition: opacity 0.5s;
} /* Modern Browsers */
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.wh-form__label {
  min-width: 150px;
}
.wh-form input[type=radio],
.wh-form input[type=checkbox] {
  position: relative;
  top: 1px;
}
.wh-form__optiondata--vertical .wh-form__optionlabel {
  margin-left: 10px;
}
.wh-form__optiondata--vertical .wh-form__subfield {
  margin-left: 10px;
}
.wh-form__fieldgroup--required > .wh-form__label:after {
  content: "*";
}
.wh-form__errors {
  list-style-type: none;
  font-style: italic;
  color: #ff0000;
  font-size: 10px;
  margin: 0;
  padding: 0;
}
.wh-form .wh-form__error {
  position: relative;
  padding-left: 5px;
  color: #ff0000;
  font-size: 12px;
}
.wh-form input[type=text],
.wh-form input[type=email],
.wh-form input[type=password],
.wh-form input[type=date],
.wh-form input[type=number],
.wh-form input[type=url],
.wh-form input[type=tel],
.wh-form input[type=time],
.wh-form select,
.wh-form textarea,
.wh-form .webshop-listcart__inputamount {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  font: inherit;
  font-size: 16px;
  padding: 7px 12px;
  max-width: 500px;
  width: 100%;
  display: block;
}
.wh-form input[type=text]:not([disabled]),
.wh-form input[type=email]:not([disabled]),
.wh-form input[type=password]:not([disabled]),
.wh-form input[type=date]:not([disabled]),
.wh-form input[type=number]:not([disabled]),
.wh-form input[type=url]:not([disabled]),
.wh-form select:not([disabled]),
.wh-form textarea:not([disabled]),
.wh-form .webshop-listcart__inputamount:not([disabled]) {
  background-color: #fff;
}
.wh-form textarea {
  height: 200px;
}
.wh-form .wh-wrdauth__loginform {
  max-width: 300px;
}
.wh-form__fieldgroup {
  margin-bottom: 10px;
}
.wh-form__fieldgroup--textedit, .wh-form__fieldgroup--pulldown, .wh-form__fieldgroup--textarea, .wh-form__fieldgroup--date {
  display: block !important;
}
.wh-form__fieldgroup--hidden {
  display: none !important;
}
.wh-form__fieldgroup .wh-form__label {
  display: block;
  margin-bottom: 5px;
}
.wh-form input[type=text].wh-form__field--error,
.wh-form input[type=email].wh-form__field--error,
.wh-form input[type=url].wh-form__field--error,
.wh-form input[type=password].wh-form__field--error,
.wh-form input[type=tel].wh-form__field--error,
.wh-form input[type=number].wh-form__field--error,
.wh-form input[type=date].wh-form__field--error,
.wh-form select.wh-form__field--error {
  border-color: #ff0000;
}
.wh-form .wh-form__buttongroup .wh-form__button {
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  outline: 0;
  cursor: pointer;
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff !important;
  border-color: var(--primary);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.wh-form .wh-form__buttongroup .wh-form__button:hover, .wh-form .wh-form__buttongroup .wh-form__button:focus {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  text-decoration: none;
  color: #fff;
}

@media (max-width: 767px) {
  .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .wh-form__fields {
    width: 100%;
    flex-basis: auto;
  }
}