@media (max-width: 1200px) {
  .ogd__twocolimgfluid img {
    height: 100%;
  }
}
.ogd__twocolimgfluid--txt {
  max-width: 600px;
  padding-top: 30px;
  padding-bottom: 30px;
  transition: all 0.3s ease;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ogd__twocolimgfluid--txt {
    max-width: 680px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .ogd__twocolimgfluid--txt {
    max-width: 920px;
  }
}
@media (max-width: 1200px) {
  .ogd__twocolimgfluid--txt {
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 620px) {
  .ogd__twocolimgfluid--txt {
    padding-left: 30px;
    padding-right: 30px;
  }
}