html.webshop--isproductpage .usps__ogd {
  margin-top: 0;
  margin-bottom: 0;
}

.usps__ogd {
  margin-top: -80px;
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .usps__ogd {
    margin-top: -48px;
  }
}

.usps__ogd ul {
  list-style: none;
  padding: 0;
}
@media (min-width: 1350px) {
  .usps__ogd ul {
    justify-content: space-between;
  }
}
.usps__ogd ul li {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1350px) {
  .ogd_usps__splide {
    visibility: visible;
  }
}