.ogd__reviewslider--quote {
  position: absolute;
  width: 100px;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
}
@media (max-width: 765px) {
  .ogd__reviewslider--quote {
    width: 75px;
    height: 75px;
    right: -10px;
    left: inherit;
  }
}
.ogd__reviewslider--quote i {
  color: var(--secondary);
  font-size: 68px;
}
@media (max-width: 765px) {
  .ogd__reviewslider--quote i {
    font-size: 50px;
  }
}
@media (min-width: 992px) {
  .ogd__reviewslider .ps-lg-6 {
    padding-left: 6rem;
  }
}
.ogd__reviewslider__item--comment p {
  line-height: 1.3;
}
.ogd__reviewslider .rating-stars {
  background-image: url(../../web/img/stars-review-grey.png);
  display: inline-block;
  height: 21px;
  position: relative;
  width: 107px;
  clear: both;
}
.ogd__reviewslider .rating-stars > .inner {
  background-image: url(../../web/img/stars-review-gold.png);
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}