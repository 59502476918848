.la,
.las,
.lar,
.lal,
.lad,
.lab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.la-angle-right:before {
  content: "\f105";
}

.la-angle-up:before {
  content: "\f106";
}

.la-angle-down:before {
  content: "\f107";
}

.la-arrow-down:before {
  content: "\f063";
}

.la-arrow-left:before {
  content: "\f060";
}

.la-arrow-right:before {
  content: "\f061";
}

.la-bars:before {
  content: "\f0c9";
}

.la-bed:before {
  content: "\f236";
}

.la-bicycle:before {
  content: "\f206";
}

.la-book:before {
  content: "\f02d";
}

.la-calendar:before {
  content: "\f133";
}

.la-comment:before {
  content: "\f086";
}

.la-envelope:before {
  content: "\f0e0";
}

.la-file-invoice:before {
  content: "\f570";
}

.la-facebook-f:before {
  content: "\f39e";
}

.la-filter:before {
  content: "\f0b0";
}

.la-hiking:before {
  content: "\f6ec";
}

.la-info-circle:before {
  content: "\f05a";
}

.la-instagram:before {
  content: "\f16d";
}

.la-male:before {
  content: "\f183";
}

.la-map-marked-alt:before {
  content: "\f5a0";
}

.la-minus:before {
  content: "\f068";
}

.la-phone:before {
  content: "\f095";
}

.la-plus:before {
  content: "\f067";
}

.la-quote-right:before {
  content: "\f10e";
}

.la-search:before {
  content: "\f002";
}

.la-star:before {
  content: "\f005";
}

.la-star-half-alt:before {
  content: "\f5c0";
}

.la-star-half:before {
  content: "\f089";
}

.la-suitcase:before {
  content: "\f0f2";
}

.la-times:before {
  content: "\f00d";
}

.la-utensils:before {
  content: "\f2e7";
}

.la-whatsapp:before {
  content: "\f232";
}

.la-walk:before {
  content: "\f554";
}

@font-face {
  font-family: "Line Awesome Brands";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../fonts/la-brands-400.woff2") format("woff2");
}
.lab {
  font-family: "Line Awesome Brands";
}

@font-face {
  font-family: "Line Awesome Free";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/la-regular-400.woff2");
}
.lar {
  font-family: "Line Awesome Free";
  font-weight: 400;
}

@font-face {
  font-family: "Line Awesome Free";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/la-solid-900.woff2") format("woff2");
}
.la,
.las {
  font-family: "Line Awesome Free";
  font-weight: 900;
}