html.webshop--isproductpage .option__kleur {
  display: none;
}
html.webshop--isproductpage .swatch-container {
  display: inline-block;
  text-align: center;
  margin: 0 15px 10px 0;
  background: #fff;
  border: 1px solid var(--border-color-light);
  border-radius: 3px;
  padding-bottom: 4px;
  cursor: pointer;
}
html.webshop--isproductpage .swatch-container .color-swatch {
  display: inline-block;
  width: 75px;
  height: 40px;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-3005 {
  background-color: #5E2129;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-5003 {
  background-color: #203856;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-6009 {
  background-color: #32372B;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-7015 {
  background-color: #444652;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-7016 {
  background-color: #2A3134;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-7021 {
  background-color: #23272A;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-7039 {
  background-color: #6B6861;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-8019 {
  background-color: #3E3634;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-9001 {
  background-color: #FEF5E1;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-9005 {
  background-color: #0A0A0A;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-9010 {
  background-color: #ffffff;
}
html.webshop--isproductpage .swatch-container .color-swatch.ral-9016 {
  background-color: #F6F6F6;
}
html.webshop--isproductpage .swatch-container .swatch-title {
  font-size: 12px;
  color: var(--font-color);
}
html.webshop--isproductpage .swatch-container.selected {
  border: 1px solid var(--black);
}
html.webshop--isproductpage .usps__ogd {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
html.webshop--isproductpage .usps__ogd ul {
  display: block !important;
}
html.webshop--isproductpage .usps__ogd ul li {
  display: flex;
  align-items: center;
}
html.webshop--isproductpage .product__tags .product__tag {
  display: flex;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
}
html.webshop--isproductpage .product__tags .product__tag:hover, html.webshop--isproductpage .product__tags .product__tag:focus {
  background-color: var(--light);
  transform: translateY(-5px);
}