.ogd__productchoice {
  padding: 50px 0;
}
.ogd__productchoice__item {
  position: relative;
  overflow: hidden;
}
.ogd__productchoice__item a {
  text-decoration: none;
  color: var(--white);
}
.ogd__productchoice__item a h3 {
  color: var(--white);
}
.ogd__productchoice__item--txt {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  color: var(--white);
  transition: all 0.3s ease;
}
.ogd__productchoice__item--desc {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}
.ogd__productchoice__item:hover .ogd__productchoice__item--desc, .ogd__productchoice__item:focus .ogd__productchoice__item--desc {
  opacity: 1;
  max-height: 100px;
}
.ogd__productchoice__item:hover:before, .ogd__productchoice__item:focus:before {
  height: 250px;
}
.ogd__productchoice__item:before {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: rgba(var(--dark-rgb), 0.8);
  transform: skewY(-5deg);
  transform-origin: top left;
  z-index: 0;
  transition: all 0.3s ease;
}