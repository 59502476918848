.ogd__forminclude {
  margin: 100px 0 50px 0;
  background-repeat: no-repeat;
  background-position: center center;
}
.ogd__forminclude input.form-control {
  max-width: 500px;
}
.ogd__forminclude--advisor {
  position: absolute;
  bottom: 0;
  right: -125px;
}
.ogd__forminclude--arrow {
  position: absolute;
  top: -140px;
  left: -200px;
}
@media (max-width: 767px) {
  .ogd__forminclude {
    margin: 30px 0 10px 0;
    overflow: hidden;
    padding-bottom: 50px;
  }
}