.site__footer {
  position: relative;
  padding: 0 0 50px;
}
.site__footer__usps {
  margin-bottom: -40px;
}
@media (max-width: 767px) {
  .site__footer__usps {
    margin-bottom: 20px;
  }
}
.site__footer__usps--txt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 65px !important;
  padding-right: 65px !important;
}
@media (max-width: 1200px) {
  .site__footer__usps--txt {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
@media (max-width: 565px) {
  .site__footer__usps--txt {
    display: block;
  }
}
.site__footer__usps ul.checkmarks {
  display: inline-block;
  position: relative;
  top: -5px;
  margin-bottom: 0;
}
@media (min-width: 800px) and (max-width: 1200px) {
  .site__footer__usps ul.checkmarks {
    column-count: 2;
  }
}
.site__footer__usps ul.checkmarks li {
  display: inline;
  font-size: 16px;
}
@media (max-width: 1200px) {
  .site__footer__usps ul.checkmarks li {
    display: flex;
  }
}
.site__footer__usps ul.checkmarks li:not(:last-child) {
  margin-right: 2.5rem;
}
.site__footer__usps ul.checkmarks li:before {
  background-position: 14px 8px;
}
@media (max-width: 767px) {
  .site__footer .row.g-5 {
    --bs-gutter-y: 0;
    --bs-gutter-x: 0;
  }
}
.site__footer h5 {
  font-family: var(--theme-heading-font);
  font-size: 21px;
  margin-bottom: 10px;
  color: var(--white);
}
.site__footer a,
.site__footer li,
.site__footer p {
  font-size: 14px;
  line-height: 24px;
}
.site__footer a {
  text-decoration: none;
  color: var(--secondary);
}
.site__footer ul.angles li {
  position: relative;
  transition: all 0.3s ease;
  left: 0;
}
.site__footer ul.angles li:before {
  color: var(--secondary);
}
.site__footer ul.angles li:hover, .site__footer ul.angles li:focus {
  left: 5px;
}
.site__footer ul.angles li a {
  color: var(--white);
  line-height: 32px;
}
.site__footer .footer__widgets {
  padding-top: 100px;
  max-width: 1600px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .site__footer .footer__widgets {
    padding-top: 0;
  }
}
@media (max-width: 1600px) {
  .site__footer .footer__widgets {
    border-radius: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .site__footer .footer__widgets {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
  }
  .site__footer .footer__widgets .footer__widget:nth-child(4) {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  }
}
.site__footer .footer__widgets .col-collapse .collapse {
  display: inherit;
}
@media (max-width: 767.98px) {
  .site__footer .footer__widgets .col-collapse {
    padding-left: 0;
    padding-right: 0;
  }
  .site__footer .footer__widgets .col-collapse .collapse {
    display: none;
  }
  .site__footer .footer__widgets .col-collapse .collapse.show {
    display: block;
  }
  .site__footer .footer__widgets .col-collapse .title {
    margin-bottom: 10px;
  }
  .site__footer .footer__widgets .col-collapse .title .h5 {
    color: var(--white);
  }
  .site__footer .footer__widgets .col-collapse .title[aria-expanded=true] .la-angle-down,
  .site__footer .footer__widgets .col-collapse .title .la-angle-up {
    display: none;
  }
  .site__footer .footer__widgets .col-collapse .title[aria-expanded=true] .la-angle-up {
    display: block;
  }
  .site__footer .footer__widgets .col-collapse .title:hover {
    cursor: pointer;
  }
  .site__footer .footer__widgets .col-collapse:not(:last-child) {
    border-bottom: 1px solid rgba(var(--border-color-light-rgb), 0.2);
  }
  .site__footer .footer__widgets .row {
    margin-right: 0;
    margin-left: 0;
  }
}
.site__footer .footer__copyright p,
.site__footer .footer__bottom p {
  margin-bottom: 0;
}
@media (min-width: 566.98px) {
  .site__footer .footer__copyright {
    padding-top: 1.5rem;
  }
}