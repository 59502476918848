.imagewithcaption {
  position: relative;
}
.imagewithcaption--caption {
  position: absolute;
  right: 30px;
  left: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  width: fit-content;
  text-align: center;
  z-index: 1;
  word-break: break-word;
}
.imagewithcaption--caption h1, .imagewithcaption--caption .h1,
.imagewithcaption--caption h2, .imagewithcaption--caption .h2,
.imagewithcaption--caption h3, .imagewithcaption--caption .h3,
.imagewithcaption--caption h4, .imagewithcaption--caption .h4,
.imagewithcaption--caption p,
.imagewithcaption--caption a,
.imagewithcaption--caption li {
  color: var(--white);
}
.imagewithcaption--caption p {
  font-size: 1.3rem;
}
@media (max-width: 565px) {
  .imagewithcaption--caption p {
    font-size: 1.1rem;
  }
}
.imagewithcaption--img {
  display: block;
  height: 48vh;
  background-color: var(--black);
}
@media (max-width: 767px) {
  .imagewithcaption--img {
    height: 60vh;
  }
}
.imagewithcaption--img img {
  opacity: 0.5;
}